import axios from 'axios' // 引入axios
import { Toast } from 'vant';
import context from '@/main'
import router from '@/router/index'
Toast.setDefaultOptions('loading', { duration: 200 });

const service = axios.create({
  baseURL: 'https://satisfaction.thape.com.cn/api/satisfaction/',
  timeout: 99999
})
let acitveAxios = 0
let timer
const showLoading = () => {
  acitveAxios++
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(() => {
    if (acitveAxios > 0) {
      context.$bus.emit('showLoading')
    }
  }, 400)
}

const closeLoading = () => {
  acitveAxios--
  if (acitveAxios <= 0) {
    clearTimeout(timer)
    context.$bus.emit('closeLoading')
  }
}
// http request 拦截器
service.interceptors.request.use(
  config => {
    if (!config.donNotShowLoading) {
      showLoading()
    }
    const token = localStorage.getItem('token');
    config.data = JSON.stringify(config.data)
    config.headers = {
      'Content-Type': 'application/json',
      'token': token ? token : '',
    }
    return config
  },
  error => {
    closeLoading()
    Toast({duration:3000,message:error});
    return error
  }
)

// http response 拦截器
service.interceptors.response.use(
  response => {
    closeLoading()
    if (response.data.code === 1 || response.headers.success === 'true') {
      return response.data
    } else {
        Toast({duration:3000,message:response.data.msg || decodeURI(response.headers.msg)});
        return response.data.msg ? response.data : response
    }
  },
  error => {
    if (error && error.response && error.response.status == 401) {
        Toast(error.response.data.msg);
        localStorage.removeItem('token');
        setTimeout(ress=> {
            router.push({ name: 'LoginWechat' })
        }, 1500); 
        return false;
    }
        closeLoading()
        Toast({duration:3000,message:error});
        return error
  }
)

export default service
